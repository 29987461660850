import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonMedia } from '@/design-system/atoms/ButtonMedia';
import { Card } from '@/design-system/atoms/Cards/Card';
import { Image } from '@/design-system/atoms/Image';
import { VideoModal } from '@/design-system/atoms/Modals';
import Typography from '@/design-system/atoms/Typography';
import { Video } from '@/design-system/atoms/Video';
import { secondsToHoursMinutesSeconds } from '@/utils/seconds-to-hours-minutes-seconds';

import styles from './CardVideo.module.scss';

const CardVideoHeader = (props) => {
    const { image, posterImage, duration, serverUrl, asset } = props;

    const imageProps = React.useMemo(() => {
        if (image) {
            return image;
        } else if (posterImage) {
            return {
                defaultImgSrc: posterImage,
                alt: '',
            };
        } else if (serverUrl && asset) {
            return {
                defaultImgSrc: `${serverUrl}/${asset}`,
                alt: '',
            };
        }

        return null;
    }, [image, posterImage, serverUrl, asset]);

    return (
        <div className={styles['thumbnail']}>
            {imageProps && (
                <Image {...imageProps} defaultAspectRatio="16/9" mobileAspectRatio="16/9" />
            )}
            <div className={styles['overlay']} />
            {duration != null && duration !== 0 && (
                <div className={styles['duration']}>
                    <div className={styles['duration__background']} />
                    <Typography
                        className={styles['duration__text']}
                        variant="t1"
                        content={secondsToHoursMinutesSeconds(duration)}
                    />
                </div>
            )}
            <div
                className={classnames(
                    'card-video__header card-video__play-button-wrapper',
                    styles['play-button'],
                )}
            >
                <ButtonMedia
                    component="span"
                    className="card-video__play-button"
                    icon="play"
                    role="presentation"
                    aria-hidden="true"
                />
            </div>
        </div>
    );
};

export const CardVideo = (props) => {
    const { className, image, duration, title, body, analytics } = props;

    const [modalMediaOpen, setModalMediaOpen] = React.useState(false);

    return (
        <>
            <Card.Root isClickableCard>
                <Card.Container
                    className={className}
                    onClick={(e) => {
                        e.preventDefault();
                        setModalMediaOpen(true);
                    }}
                >
                    <CardVideoHeader
                        {...props}
                        image={image}
                        duration={duration}
                        setModalMediaOpen={setModalMediaOpen}
                    />
                    <Card.Content>
                        {title && (
                            <Typography
                                className={styles['title']}
                                variant="e1"
                                content={title}
                                lineClamp={{ sm: 3, lg: 2 }}
                            />
                        )}
                        {body && (
                            <Typography
                                className={styles['body']}
                                variant="t3"
                                content={body}
                                lineClamp={{ sm: 4, lg: 2 }}
                            />
                        )}
                    </Card.Content>
                </Card.Container>
            </Card.Root>
            <VideoModal
                video={props}
                isVisible={modalMediaOpen}
                onClose={setModalMediaOpen}
                analytics={analytics ? { ...analytics, actionLabel: title } : undefined}
            />
        </>
    );
};

CardVideo.propTypes = {
    ...Video.propTypes,
    /**
     * Video Thumbnail (Image PropTypes)
     */
    image: PropTypes.shape(Image.propTypes),
    /**
     * Video duration in seconds
     */
    duration: PropTypes.number,
};
